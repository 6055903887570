/* 
 * ...::: THEME :::...
 * Theming that applies specifically to the Trello in-app dialogs. This includes any overrides to the 
 * default, parent theme.
 */
.orah-dialog-style .ant-btn:hover[type="button"], .orah-dialog-style .ant-btn:focus[type="button"], .orah-dialog-style .ant-btn:active[type="button"] {
  color: var(--brand-purple);
  border-color: var(--brand-purple); }

.orah-dialog-style .ant-btn.ant-btn-primary, .orah-dialog-style .ant-btn.ant-btn-primary:hover, .orah-dialog-style .ant-btn.ant-btn-primary:focus {
  background-color: var(--brand-purple);
  border: var(--brand-purple); }

.orah-dialog-style .ant-btn-primary:focus {
  opacity: 0.8; }

.orah-dialog-style .ant-btn-link {
  color: var(--brand-purple); }

.orah-dialog-style .tt-report-filter-item .ant-select .ant-select-selection:hover {
  border-color: var(--brand-purple); }

.orah-dialog-style .tt-report-filter-item .ant-calendar-picker-input.ant-input:hover {
  border-color: var(--brand-purple); }

.orah-dialog-style .ant-select-selection:hover {
  border-color: var(--brand-purple); }

.orah-dialog-style .ant-input-number:hover {
  border-color: var(--brand-purple); }

.orah-dialog-style .ant-input:hover {
  border-color: var(--brand-purple); }

.orah-dialog-style .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: var(--brand-purple); }

/* Report Filter */
.time-tracking-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5px;
  vertical-align: middle;
  line-height: 2em; }

div.tt-filter-item {
  padding: 5px;
  display: table; }

.ant-table-wrapper.workLogTable .ant-table-row > td {
  width: 60px; }

.tt-log-input .ant-select {
  width: 250px; }

.tt-log-input .ant-input-number {
  width: 70px; }

:root {
  height: auto; }

.tt-form-mandatory {
  color: var(--brand-error); }

/* ..:: Ant Design ::.. 
 * Form styling that operates on the provided Ant Design components.
 */
.ant-row.ant-form-item {
  margin-bottom: inherit; }

.ant-form-item-control {
  line-height: 1px !important; }

.ant-calendar-picker.has-error input.ant-input {
  border-color: var(--brand-error); }
